<template>
  <full-view :title="getTitle">
    <template v-slot:toolbar>
      <v-btn :disabled="!valid" large depressed color="primary" @click="save">
        {{ $t("general.save") }}
      </v-btn>
    </template>

    <v-card flat>
      <v-card-text class="overflow-y-auto px-0 pt-3" :style="getViewHeightStyle(36)">
        <v-form ref="form" v-model="valid" class="py-5 px-5">
          <v-row>
            <v-col>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="item.name"
                    :counter="64"
                    :rules="rules.name"
                    :label="$t('general.name')"
                    required
                    dense
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="item.username"
                    :counter="50"
                    :rules="rules.username"
                    :label="$t('user.username')"
                    required
                    disabled
                    dense
                    autocomplete="off"
                  />
                </v-col>
              </v-row>

            </v-col>
            <v-col cols="12" md="5" v-if="editMode">
              <image-uploader avatar :key-attr="item.uuid" :title="$t('user.avatar')" dir="avatars" :edit-mode="editMode" />
            </v-col>
          </v-row>

          <br />
          <v-divider />

          <v-btn
            :disabled="!valid"
            :dark="valid"
            color="success"
            class="mr-4"
            tile
            depressed
            @click="save"
          >
            <v-icon>mdi-content-save</v-icon>
            {{ $t("general.save") }}
          </v-btn>

          <v-btn
            color="primary"
            tile
            depressed
            @click="reset"
          >
            <v-icon>mdi-undo-variant</v-icon>
            {{ $t("general.undo") }}
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </full-view>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FullView from "@/common/layouts/FullView";
import ImageUploader from "@/common/components/ImageUploader";

export default {
  name: "UserForm",
  components: { FullView, ImageUploader },
  props: {
    profile: Boolean,
    item: {
      type: Object,
      required: true,
      default() {
        return {
          id: 0,
          username: "",
          name: "",
          email: "",
          phone: "",
          updatePassword: false,
          password: "",
          confirmPassword: "",
          avatar: null,
          enabled: false,
          skipConfirmation: false,
        };
      },
    },
  },
  data() {
    return {
      valid: false,
      originalState: { id: 0 },
      rules: {
        name: [
          (v) => !!v || "Name is required",
          (v) => (!!v && v.length >= 2) || "Name must be at least 2 characters",
          (v) =>
            (!!v && v.length <= 64) ||
            "Must be less than or eaqual to 64 characters",
        ],
        username: [
          (v) => !!v || "Username is required",
          (v) =>
            (!!v && v.length >= 4) || "Username must be at least 4 characters",
          (v) =>
            (!!v && v.length <= 50) ||
            "Name must be less than or eaqual to 50 characters",
          (v) => {
            const re = /^[a-zA-Z0-9]+([_ -]?[a-zA-Z0-9])*$/;
            return (
              re.test(String(v).toLowerCase()) ||
              "Username must start with an alphanumeric character, " +
                "can have a single (- or _) sepcial charactor " +
                "and/or any number of alphanumeric characters within the limit"
            );
          },
        ],
        type: [
          (v) => !!v || "User type is required",
        ],
        phone: [
          (v) => !!v || "Phone number is required",
          (v) => (!!v && v.length >= 8) || "Not less than 8 numbers",
          (v) => {
            const re = /^[+\-/0-9 ]*$/;
            return (
              re.test(String(v).toLowerCase()) ||
              "Only numbers, spaces and (+ - or /) sepcial charactors"
            );
          },
        ],
        password: [
          (v) => !!v || "Password is required",
          (v) =>
            (!!v && v.length >= 8) || "Password must be at least 8 characters",
        ],
        confirm: [
          (v) => !!v || "Password is required",
          (v) =>
            v === this.item.password || "Password and confirmation must match",
        ],
        email: [
          (v) => !!v || "Email is required",
          (v) => {
            const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
            return !!v && re.test(String(v).toLowerCase());
          },
        ],
      },
    };
  },
  mounted() {
    // Object.assign(this.item, this.data);
    Object.assign(this.originalState, this.item);
    this.loadTypes(true);
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("types", ["getTypesByGroup"]),

    getTitle() {
      if (this.editMode && !this.profile) {
        return this.$t("user.edit");
      } else if (this.profile) {
        return this.$t("app.route.profile");
      } else {
        return this.$t("user.new");
      }
    },
    isCurrentUser() {
      const { username } = this.$getUserInfo();
      return username === this.item.username;
    },
    changed() {
      return this.item && !this.compareObjects(this.item, this.originalState);
    },
    idParam() {
      return parseInt(this.$route.params.id);
    },
    editMode() {
      return this.idParam > 0 || this.profile;
    },
  },
  methods: {
    ...mapActions("types", ["loadTypes"]),

    save() {
      if (!this.editMode) {
        this.item.updatePassword = true;
      }

      this.$emit("save", {
        item: this.item,
        // tempAvatarCode: this.tempAvatarCode,
        editMode: this.editMode,
      });
    },
    reset() {
      Object.assign(this.item, this.originalState);
    },
  },
};
</script>
