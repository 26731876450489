import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('full-view',{attrs:{"title":_vm.getTitle},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c(VBtn,{attrs:{"disabled":!_vm.valid,"large":"","depressed":"","color":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("general.save"))+" ")])]},proxy:true}])},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,{staticClass:"overflow-y-auto px-0 pt-3",style:(_vm.getViewHeightStyle(36))},[_c(VForm,{ref:"form",staticClass:"py-5 px-5",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_c(VCol,[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"counter":64,"rules":_vm.rules.name,"label":_vm.$t('general.name'),"required":"","dense":""},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})],1),_c(VCol,[_c(VTextField,{attrs:{"counter":50,"rules":_vm.rules.username,"label":_vm.$t('user.username'),"required":"","disabled":"","dense":"","autocomplete":"off"},model:{value:(_vm.item.username),callback:function ($$v) {_vm.$set(_vm.item, "username", $$v)},expression:"item.username"}})],1)],1)],1),(_vm.editMode)?_c(VCol,{attrs:{"cols":"12","md":"5"}},[_c('image-uploader',{attrs:{"avatar":"","key-attr":_vm.item.uuid,"title":_vm.$t('user.avatar'),"dir":"avatars","edit-mode":_vm.editMode}})],1):_vm._e()],1),_c('br'),_c(VDivider),_c(VBtn,{staticClass:"mr-4",attrs:{"disabled":!_vm.valid,"dark":_vm.valid,"color":"success","tile":"","depressed":""},on:{"click":_vm.save}},[_c(VIcon,[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.$t("general.save"))+" ")],1),_c(VBtn,{attrs:{"color":"primary","tile":"","depressed":""},on:{"click":_vm.reset}},[_c(VIcon,[_vm._v("mdi-undo-variant")]),_vm._v(" "+_vm._s(_vm.$t("general.undo"))+" ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }